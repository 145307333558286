import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { Layout as AntLayout, Image, theme, Spin, Alert, Button } from "antd";
import { useExtraToken } from "components/ui-kit/core/extra-token";
import { ReactNode } from "react";
import { useAppSelector } from "store/hooks";
// import { resetAllData } from "store/utils/reset-all-data";
import tinyColor from "tinycolor2";
import logo from "../../../../../assets/logo-long.svg";
import smallLogo from "../../../../../assets/small_logo.svg";
import peoplePattern from "../../../../../assets/people-pattern.svg";
import { useContrastingColors } from "../../../../ui-kit/core/contrasting-colors";
import { AntIcon } from "components/ui-kit/components/ant-icon";
import { SidebarMenuContextProvider } from "./context/provider";
import { useSidebarMenuContext } from "./context";
import useGetWorkspace from "logic/hooks/get-workspace";
import { BottomMenu } from "./components/bottom-menu";
import { TopMenu } from "./components/top-menu";
import { useImpersonateLogin } from "logic/hooks/impersonate-login";

export const BETA_UI_BANNER_HEIGHT = 26;
export const IMPERSONATING_USER_ALERT_HEIGHT = 96;
export const LAYOUT_CONTENT_ID = "layout-content";

function LayoutContent(props: { children: ReactNode }) {
  // Router
  // const router = useRouter();

  // Theme token
  const { token } = theme.useToken();
  // Extra token
  const extraToken = useExtraToken();
  // Contrasting colors
  const contrastingColors = useContrastingColors();

  const workspacesLoading = useAppSelector((state) => state.workspaces.loading);

  const projectLoading = useAppSelector((state) => state.projects.loading);

  const {
    // setBetaUIEnabled,
    // betaUIEnabled,
    // setBetaUIDismissed,
    // betaUIDismissed,
    // betaUIFeatureFlagEnabled,
    isImpersonating,
    sidebarMenuCollapsed,
    setSidebarMenuCollapsed,
  } = useSidebarMenuContext();

  // const showNewUIBanner = useMemo(() => {
  //   return betaUIFeatureFlagEnabled && !betaUIDismissed;
  // }, [betaUIDismissed, betaUIFeatureFlagEnabled]);

  // is saved study memoized variable
  // const isSavedStudy = useMemo(() => router.query.studyId !== undefined, [router.query.studyId]);

  useGetWorkspace();
  useImpersonateLogin();

  const cssOverrides = `
.layout-menu .ant-menu-title-content {
  color: ${contrastingColors.get(token.colorPrimary).neutral};
}

.layout-menu .ant-menu.ant-menu-sub.ant-menu-inline {
  background-color: transparent;
}

.ant-menu-item.ant-menu-item-only-child {
  margin: 0px;
}

.ant-menu-item-only-child > .ant-menu-title-content {
  height: 205x;
  line-height: 25px;
}

.layout-menu .ant-menu-item.ant-menu-item-selected {
  background-color: ${tinyColor(token.colorPrimary).brighten(10).toRgbString()};
}

.layout-sider .ant-layout-sider-children {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.layout-menu.ant-menu .ant-menu-item.login,
.layout-menu.ant-menu .ant-menu-item.login .ant-menu-title-content,
.layout-menu.ant-menu .ant-menu-item.login:hover,
.layout-menu.ant-menu
  .ant-menu-item.login:hover
  .ant-menu-title-content {
  background-color: ${token.colorBgBase};
  color: ${token.colorPrimaryText};
}

.menu-item:hover .menu-item-icon {
  visibility: visible;
}

.menu-item .menu-item-icon.active {
  visibility: visible;
}

.menu-item-icon {
  visibility: hidden;
    
  &:hover {
    background-color: rgba(255, 255, 255, 0.5);
  }
    
  &.active:hover {      
    background-color: rgba(0, 0, 0, 0.5);
  }
}
`;
  return (
    <>
      {!!(workspacesLoading || projectLoading) && <Spin spinning={true} fullscreen />}
      {isImpersonating && (
        <Alert
          message="You are currently impersonating another user."
          description="Please be very careful with the actions and make sure to log out when you are done."
          type="error"
          banner
        />
      )}
      {/* {showNewUIBanner && (
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            backgroundColor: "#A5A6F6",
            textAlign: "center",
            paddingBlock: 4,
            height: BETA_UI_BANNER_HEIGHT,
          }}
        >
          <Typography.Paragraph style={{ fontSize: 12, margin: 0, color: "#FFFFFF" }}>
            ✍️{" "}
            {betaUIEnabled
              ? "We are trialing a new interface to create the research plan. If you prefer the old one"
              : "We are trialing a new interface to create the research plan. If you'd like to try it out"}{" "}
            <span
              onClick={() => {
                setBetaUIEnabled();
                !isSavedStudy && resetAllData();
              }}
              style={{ textDecoration: "underline", cursor: "pointer" }}
            >
              click here.
            </span>
          </Typography.Paragraph>
          <div
            style={{
              position: "absolute",
              right: 24,
              top: BETA_UI_BANNER_HEIGHT / 2 - 8,
              cursor: "pointer",
              color: "#FFFFFF",
            }}
            onClick={setBetaUIDismissed}
          >
            <AntIcon component={() => <FontAwesomeIcon icon={faClose} />} />
          </div>
        </div>
      )} */}
      <AntLayout
        style={{
          minHeight: 0,
          height: `calc(100vh - ${isImpersonating ? IMPERSONATING_USER_ALERT_HEIGHT : 0}px)`,
        }}
      >
        <style jsx global>
          {cssOverrides}
        </style>
        <AntLayout.Sider
          collapsed={sidebarMenuCollapsed}
          width={240} // added to fit workspace menu item
          className={`layout-sider`}
          style={{
            position: "relative",
            background: `url(${
              (peoplePattern as { src: string }).src
            }), linear-gradient(${extraToken.colorSideBarBg}, ${extraToken.colorSideBarBg})`,
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "50%",
              right: 0,
              transform: "translateX(50%)",
              zIndex: 10,
            }}
          >
            <Button
              shape="circle"
              onClick={() => setSidebarMenuCollapsed()}
              icon={<AntIcon icon={sidebarMenuCollapsed ? faChevronRight : faChevronLeft} />}
            />
          </div>
          <div>
            <Image
              style={{
                padding: token.paddingLG,
                paddingBottom: sidebarMenuCollapsed ? token.paddingMD : token.paddingXL,
              }}
              src={
                sidebarMenuCollapsed
                  ? (smallLogo as { src: string }).src
                  : (logo as { src: string }).src
              }
              alt="Brand"
              preview={false}
              width={sidebarMenuCollapsed ? "100%" : "85%"}
            />
            {/* Top Menu */}
            <TopMenu collapsed={sidebarMenuCollapsed} />
          </div>

          {/* Bottom Menu */}
          <BottomMenu collapsed={sidebarMenuCollapsed} />
        </AntLayout.Sider>

        {/* Content */}
        <AntLayout.Content
          id={LAYOUT_CONTENT_ID}
          style={{ overflowY: "auto", height: "100%", maxHeight: "100%" }}
        >
          {props.children}
        </AntLayout.Content>
      </AntLayout>
    </>
  );
}
type WithSidebarMenuLayoutProps = {
  children: ReactNode;
  initialBetaUIFeatureFlagEnabled?: boolean;
  ragEnabled?: boolean;
  surveysEnabled?: boolean;
  isImpersonating?: boolean;
};

export const WithSidebarMenuLayout: React.FC<WithSidebarMenuLayoutProps> = ({
  children,
  initialBetaUIFeatureFlagEnabled,
  ragEnabled,
  surveysEnabled,
  isImpersonating,
}) => {
  return (
    <SidebarMenuContextProvider
      initialBetaUIFeatureFlagEnabled={initialBetaUIFeatureFlagEnabled}
      ragEnabled={ragEnabled}
      surveysEnabled={surveysEnabled}
      isImpersonating={isImpersonating}
    >
      <LayoutContent>{children}</LayoutContent>
    </SidebarMenuContextProvider>
  );
};
