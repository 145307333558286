import { faArrowRight, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Menu, MenuProps } from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";
import { useAppSelector } from "store/hooks";
import { useAuthState } from "logic/auth/auth-state";
import { INDEX_ROUTE } from "templates/index/index-routes";
import { USER_ROUTE } from "templates/user/user-routes";
import { AuthTabParams } from "templates/index/login";
import { WorkspacesMenu } from "./workspaces-menu";
import { WORKSPACE_PLANS_ROUTE } from "templates/workspace-plans/workspace-plans-routes";
import { ProjectInviteStatus } from "store/modules/projects/types";
import { WORKSPACE_SIGNUP_ROUTE } from "templates/workspace-settings/workspace-settings.routes";

export enum BottomMenuKeys {
  // FEEDBACK = "feedback",
  LOGIN = "login",
  PROFILE = "profile",
  // TOGGLE_BETA_UI = "toggle-beta-ui",
  START_FREE_TRIAL = "start-free-trial",
  PENDING_INVITES = "pending-invites",
}

type BottomMenuProps = {
  collapsed?: boolean;
};

export const BottomMenu: React.FC<BottomMenuProps> = ({ collapsed }) => {
  const auth = useAuthState();
  // Router
  const router = useRouter();

  const activeSubscription = useAppSelector(
    (state) => state.workspaces.data.workspace?.activeSubscription
  );
  const workspace = useAppSelector((state) => state.workspaces.data);

  const isAnonymous = useMemo(
    () => auth.session.data?.user.isAnonymous,
    [auth.session.data?.user.isAnonymous]
  );

  const hasPendingInvites = useMemo(
    () =>
      !!workspace.workspacesList?.filter(
        (workspace) => workspace.status === ProjectInviteStatus.Pending
      )?.length,
    [workspace.workspacesList]
  );

  // const { betaUIEnabled, betaUIFeatureFlagEnabled, setBetaUIEnabled } = useSidebarMenuContext();

  // is saved study memoized variable
  // const isSavedStudy = useMemo(() => router.query.studyId !== undefined, [router.query.studyId]);

  // Bottom Menu Items
  const bottomMenuItems: MenuProps["items"] = useMemo(() => {
    // center items styles
    const itemStyles = {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    };

    // Feedback menu item

    const pendingInvites: ItemType = {
      label: (
        <div style={itemStyles}>
          <Button type="primary" danger>
            You have pending invites
          </Button>
        </div>
      ),
      key: BottomMenuKeys.PENDING_INVITES,
    };

    const seePlans: ItemType = {
      label: (
        <div style={itemStyles}>
          <span>See plans</span>
          <FontAwesomeIcon icon={faArrowRight} />
        </div>
      ),
      key: BottomMenuKeys.START_FREE_TRIAL,
    };

    // Feedback menu item
    // const feedback: ItemType = {
    //   label: "Send Feedback",
    //   key: BottomMenuKeys.FEEDBACK,
    //   icon: <FontAwesomeIcon icon={faThumbsUp} />,
    // };

    // Sign up menu item
    const signUp: ItemType = {
      label: (
        <div style={itemStyles}>
          <span>Sign up / Log in</span>
        </div>
      ),
      key: BottomMenuKeys.LOGIN,
      className: "login",
      icon: <FontAwesomeIcon icon={faArrowRight} />,
    };

    // Profile menu item
    const profile: ItemType = {
      label: (
        <div style={itemStyles}>
          <span>{USER_ROUTE.label}</span>
        </div>
      ),
      key: BottomMenuKeys.PROFILE,
      icon: <FontAwesomeIcon icon={faUser} />,
    };

    // Toggle Beta UI menu item
    // const toggleBetaUI: ItemType = {
    //   label: (
    //     <div style={itemStyles}>
    //       <span>Beta UI</span>
    //       <Switch checked={betaUIEnabled} />
    //     </div>
    //   ),
    //   key: BottomMenuKeys.TOGGLE_BETA_UI,
    //   icon: <FontAwesomeIcon icon={faStar} />,
    // };

    // Compose items by order
    const items = [];

    // pending invites
    if (hasPendingInvites) items.push(pendingInvites);

    // beta ui
    // if (betaUIFeatureFlagEnabled) items.push(toggleBetaUI);

    // see plans
    if (!isAnonymous && !activeSubscription) items.push(seePlans);

    // feedback
    // items.push(feedback);

    // sign up / profile
    if (isAnonymous) {
      items.push(signUp);
    } else {
      items.push(profile);
    }

    return items;
  }, [isAnonymous, activeSubscription, hasPendingInvites]);

  // Top Menu Items Selected Keys
  const bottomMenuItemsSelectedKeys = useMemo(() => {
    const isProfile = router.asPath.startsWith(USER_ROUTE.path);

    const items = [];
    if (!isAnonymous && !activeSubscription) items.push(BottomMenuKeys.START_FREE_TRIAL);
    if (isProfile) items.push(BottomMenuKeys.PROFILE);

    return items;
  }, [router.asPath, activeSubscription, isAnonymous]);

  // Menu Select Handler
  const onMenuSelect = useCallback(
    (key: string) => {
      switch (key) {
        case BottomMenuKeys.LOGIN:
          router.push(`${INDEX_ROUTE.getHref()}?login=${AuthTabParams.SignUp}`);
          break;
        case BottomMenuKeys.PROFILE:
          analytics.track("workspace:sidebar:account-settings");
          router.push(USER_ROUTE.getHref());
          break;
        // case BottomMenuKeys.FEEDBACK:
        //   showIntercom();
        //   break;
        // case BottomMenuKeys.TOGGLE_BETA_UI:
        //   analytics.track("workspace:sidebar:workspace-settings");
        //   setBetaUIEnabled();
        //   !isSavedStudy && resetAllData();
        //   break;
        case BottomMenuKeys.START_FREE_TRIAL:
          analytics.track("workspace:sidebar:workspace-plans");
          router.push(WORKSPACE_PLANS_ROUTE.getHref());
          break;
        case BottomMenuKeys.PENDING_INVITES:
          analytics.track("workspace:sidebar:workspace-plans");
          router.push(WORKSPACE_SIGNUP_ROUTE.getHref());
          break;
      }
    },
    [router]
  );

  return (
    <div
      style={{
        position: "absolute",
        bottom: "0",
        width: "100%",
      }}
    >
      <style jsx global>
        {`
          .ant-menu-title-content {
            overflow: visible !important;
          }
        `}
      </style>
      <Menu
        selectedKeys={bottomMenuItemsSelectedKeys}
        className="layout-menu"
        theme="dark"
        style={{ backgroundColor: "transparent" }}
        mode="inline"
        items={bottomMenuItems}
        onClick={(info) => onMenuSelect(info.key)}
      />
      <WorkspacesMenu collapsed={collapsed} />
    </div>
  );
};
