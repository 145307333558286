import { faArrowRight, faFolder, faGear, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { App as AntApp, Flex, Menu, MenuProps, Space } from "antd";
import { getAnalytics } from "logic/analytics/analytics";
import { useAuthState } from "logic/auth/auth-state";
import { useRouter } from "next/router";
import { useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getProjectById } from "store/modules/projects/actions";
import { INDEX_ROUTE } from "templates/index/index-routes";
import { PROJECT_SETTINGS_ROUTE } from "templates/project-settings/project-settings.routes";
import { PROJECTS_ROUTE } from "templates/projects/projects.routes";

type ProjectsMenuProps = {
  collapsed?: boolean;
};

const ADD_PROJECT_ITEM_ID = "add-project";
const SEE_ALL_PROJECTS_ITEM_ID = "see-all-projects";
const PROJECTS_MENU_KEY = "projects";

export const ProjectsMenu: React.FC<ProjectsMenuProps> = ({ collapsed }) => {
  const dispatch = useAppDispatch();
  const projects = useAppSelector((state) => state.projects.data);
  const projectsList = useMemo(() => projects?.projectsList, [projects?.projectsList]);
  const project = useMemo(() => projects?.project, [projects?.project]);
  const router = useRouter();

  const auth = useAuthState();
  const user = auth.session.data?.user;
  const workspaces = useAppSelector((state) => state.workspaces);
  const workspace = useMemo(() => workspaces.data.workspace, [workspaces.data.workspace]);
  const isWorkspaceOwner = useMemo(
    () => workspace?.owners.includes(user?.uid ?? ""),
    [user?.uid, workspace]
  );

  const { message } = AntApp.useApp();

  const analytics = getAnalytics();
  const [openKeys, setOpenKeys] = useState<string[] | undefined>([]);

  const items: Required<MenuProps>["items"] = useMemo(
    () => [
      {
        label: project?.name,
        icon: <FontAwesomeIcon icon={faFolder} />,
        key: PROJECTS_MENU_KEY,
        children: projectsList
          ?.map((item) => ({
            label: (
              <Flex
                justify="space-between"
                align="center"
                style={{
                  width: "100%",
                  height: "100%",
                }}
                className="menu-item"
              >
                <span style={{ marginRight: 16 }}>{item.name}</span>
                <FontAwesomeIcon
                  icon={faGear}
                  style={{
                    cursor: "pointer",
                    padding: 8,
                    borderRadius: 4,
                  }}
                  className={`menu-item-icon ${item.id === project?.id ? "active" : ""}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    router.push(PROJECT_SETTINGS_ROUTE.getHref(item.id));
                  }}
                />
              </Flex>
            ),
            key: item.id,
          }))
          .concat([
            ...(isWorkspaceOwner
              ? [
                  {
                    label: (
                      <Space
                        align="center"
                        style={{ width: "100%", justifyContent: "space-between" }}
                      >
                        <span style={{ marginRight: 16 }}>Create New Project</span>
                        <FontAwesomeIcon icon={faPlus} style={{ padding: 8 }} />
                      </Space>
                    ),
                    key: ADD_PROJECT_ITEM_ID,
                  },
                ]
              : []),
            {
              label: (
                <Space align="center" style={{ width: "100%", justifyContent: "space-between" }}>
                  <span>See All Projects</span>
                  <FontAwesomeIcon icon={faArrowRight} style={{ padding: 8 }} />
                </Space>
              ),
              key: SEE_ALL_PROJECTS_ITEM_ID,
            },
          ]),
      },
    ],
    [projectsList, project?.name, router, project?.id, isWorkspaceOwner]
  );

  const handleMenuSelection = (key: string) => {
    setOpenKeys([]);

    if (key === PROJECTS_MENU_KEY) return;

    if (key === ADD_PROJECT_ITEM_ID) {
      analytics.track("project:sidebar:project-switcher:create");
      return router.push(PROJECT_SETTINGS_ROUTE.path);
    }

    if (key === SEE_ALL_PROJECTS_ITEM_ID) {
      analytics.track("project:sidebar:project-switcher:see-all");
      return router.push(PROJECTS_ROUTE.path);
    }

    analytics.track("project:sidebar:project-switcher:switch");
    router.push(INDEX_ROUTE.path);
    dispatch(
      getProjectById({
        projectId: key,
        onSuccess: (newProjectName) => {
          message.info(`You are now in ${newProjectName || ""} project`);
        },
      })
    );
  };

  const isHome = router.asPath === "/";

  return (
    <div className="projects-menu">
      <style jsx global>
        {`
          .projects-menu {
            .ant-menu-item-icon {
              color: ${isHome ? "#6e6fa9 !important" : "inherit"};
            }
          }
        `}
      </style>
      <Menu
        className="layout-menu"
        theme="dark"
        mode={collapsed ? "inline" : "vertical"}
        items={items}
        selectedKeys={[project?.id || "0"]}
        openKeys={openKeys}
        onOpenChange={(keys) => {
          analytics.track("workspace:sidebar:workspace-switcher");
          setOpenKeys(keys);
        }}
        style={{ backgroundColor: "transparent" }}
        onSelect={(e) => handleMenuSelection(e.key)}
        subMenuCloseDelay={0.7}
      />
    </div>
  );
};
