import { faArrowRight, faBriefcase, faGear, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { App as AntApp, Flex, Menu, MenuProps, Space } from "antd";
import { getAnalytics } from "logic/analytics/analytics";
import { useRouter } from "next/router";
import { useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getWorkspaceById } from "store/modules/workspaces/actions";
import { INDEX_ROUTE } from "templates/index/index-routes";
import { WORKSPACE_SETTINGS_ROUTE } from "templates/workspace-settings/workspace-settings.routes";
import { WORKSPACES_ROUTE } from "templates/workspaces/workspaces.routes";

type WorkspacesMenuProps = {
  collapsed?: boolean;
};

const WORKSPACE_MENU_KEY = "workspaces";
const ADD_WORKSPACE_ITEM_ID = "add-workspace";
const SEE_ALL_WORKSPACES_ITEM_ID = "see-all-workspaces";

export const WorkspacesMenu: React.FC<WorkspacesMenuProps> = ({ collapsed }) => {
  const dispatch = useAppDispatch();
  const workspaces = useAppSelector((state) => state.workspaces.data);
  const workspacesList = useMemo(() => workspaces?.workspacesList, [workspaces?.workspacesList]);
  const workspace = useMemo(() => workspaces?.workspace, [workspaces?.workspace]);
  const router = useRouter();

  const { message } = AntApp.useApp();

  const analytics = getAnalytics();
  const [openKeys, setOpenKeys] = useState<string[] | undefined>([]);

  const activeSubscriptionWorkspaces = useMemo(
    () => workspacesList?.filter((workspace) => workspace.active_subscription),
    [workspacesList]
  );

  const inactiveSubscriptionWorkspaces = useMemo(
    () => workspacesList?.filter((workspace) => !workspace.active_subscription),
    [workspacesList]
  );

  const items: Required<MenuProps>["items"] = useMemo(
    () => [
      {
        label: workspace?.name,
        key: WORKSPACE_MENU_KEY,
        icon: <FontAwesomeIcon icon={faBriefcase} />,
        children: [
          ...(activeSubscriptionWorkspaces?.length
            ? [
                {
                  key: "active-subscription-workspaces",
                  label: "Active Workspaces",
                  type: "group",
                  children: activeSubscriptionWorkspaces?.map((item) => ({
                    label: (
                      <Flex
                        justify="space-between"
                        align="center"
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                        className="menu-item"
                      >
                        <span style={{ marginRight: 16 }}>{item.name}</span>
                        <FontAwesomeIcon
                          icon={faGear}
                          style={{
                            cursor: "pointer",
                            padding: 8,
                            borderRadius: 4,
                          }}
                          className={`menu-item-icon ${item.id === workspace?.id ? "active" : ""}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            router.push(WORKSPACE_SETTINGS_ROUTE.getHref(item.id));
                          }}
                        />
                      </Flex>
                    ),
                    key: item.id,
                  })),
                },
              ]
            : []),
          ...(inactiveSubscriptionWorkspaces?.length
            ? [
                {
                  key: "inactive-subscription-workspaces",
                  label: "Inactive Workspaces",
                  type: "group",
                  children: inactiveSubscriptionWorkspaces?.map((item) => ({
                    label: (
                      <Flex
                        justify="space-between"
                        align="center"
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                        className="menu-item"
                      >
                        <span style={{ marginRight: 16 }}>{item.name}</span>
                        <FontAwesomeIcon
                          icon={faGear}
                          style={{
                            cursor: "pointer",
                            padding: 8,
                            borderRadius: 4,
                          }}
                          className={`menu-item-icon ${item.id === workspace?.id ? "active" : ""}`}
                          onClick={(e) => {
                            e.stopPropagation();
                            router.push(WORKSPACE_SETTINGS_ROUTE.getHref(item.id));
                          }}
                        />
                      </Flex>
                    ),
                    key: item.id,
                  })),
                },
              ]
            : []),
          {
            key: "actions",
            label: "Actions",
            type: "group",
            children: [
              {
                label: (
                  <Space align="center" style={{ width: "100%", justifyContent: "space-between" }}>
                    <span style={{ marginRight: 16 }}>Create New Workspace</span>
                    <FontAwesomeIcon icon={faPlus} style={{ padding: 8 }} />
                  </Space>
                ),
                key: ADD_WORKSPACE_ITEM_ID,
              },
              {
                label: (
                  <Space align="center" style={{ width: "100%", justifyContent: "space-between" }}>
                    <span>See All Workspaces</span>
                    <FontAwesomeIcon icon={faArrowRight} style={{ padding: 8 }} />
                  </Space>
                ),
                key: SEE_ALL_WORKSPACES_ITEM_ID,
              },
            ],
          },
        ],
      },
    ],
    [
      workspace?.name,
      workspace?.id,
      router,
      activeSubscriptionWorkspaces,
      inactiveSubscriptionWorkspaces,
    ]
  );

  const handleMenuSelection = (key: string) => {
    setOpenKeys([]);

    if (key === WORKSPACE_MENU_KEY) return;

    if (key === ADD_WORKSPACE_ITEM_ID) {
      analytics.track("workspace:sidebar:workspace-switcher:create");
      return router.push(WORKSPACE_SETTINGS_ROUTE.path);
    }

    if (key === SEE_ALL_WORKSPACES_ITEM_ID) {
      analytics.track("workspace:sidebar:workspace-switcher:see-all");
      return router.push(WORKSPACES_ROUTE.path);
    }

    analytics.track("workspace:sidebar:workspace-switcher:switch");

    router.push(INDEX_ROUTE.getHref());
    dispatch(
      getWorkspaceById({
        workspaceId: key,
        onSuccess: (newWorkspaceName) => {
          message.info(`You are now in ${newWorkspaceName || ""} workspace`);
        },
      })
    );
  };

  return (
    <Menu
      className="layout-menu"
      theme="dark"
      mode={collapsed ? "inline" : "vertical"}
      items={items}
      selectedKeys={[workspace?.id || "0"]}
      openKeys={openKeys}
      onOpenChange={(keys) => {
        analytics.track("workspace:sidebar:workspace-switcher");
        setOpenKeys(keys);
      }}
      style={{ backgroundColor: "transparent" }}
      onSelect={(e) => handleMenuSelection(e.key)}
      subMenuCloseDelay={0.7}
    />
  );
};
