import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { SidebarMenuContext } from ".";
import { useBetaUI } from "logic/hooks/betaUI";
import { useAppDispatch } from "store/hooks";
import { resetProjectSettings, setProjectSettings } from "store/modules/projects/actions";
import { ProjectSettings } from "store/modules/projects/types";
import { useRouter } from "next/router";
import { useSidebarMenuCollapsed } from "logic/hooks/sidebar-menu-collapsed";

type WorkspacePageContextProviderProps = {
  children: ReactNode;
  initialBetaUIFeatureFlagEnabled?: boolean;
  ragEnabled?: boolean;
  surveysEnabled?: boolean;
  isImpersonating?: boolean;
};

export const SidebarMenuContextProvider: React.FC<WorkspacePageContextProviderProps> = ({
  children,
  initialBetaUIFeatureFlagEnabled,
  ragEnabled,
  surveysEnabled,
  isImpersonating,
}) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { toggleBetaUI, markBetaUIAsDismissed, getBetaUIEnabled } = useBetaUI();
  const { getSidebarMenuCollapsed, toggleSidebarMenuCollapsed } = useSidebarMenuCollapsed();

  const [betaUIEnabled, _setBetaUIEnabled] = useState<boolean | undefined>(true);
  const [betaUIDismissed, _setBetaUIDismissed] = useState<boolean | undefined>(true);

  const [sidebarMenuCollapsed, _setSidebarMenuCollapsed] =
    useState<boolean>(getSidebarMenuCollapsed());

  const isSavedStudy = useMemo(() => router.query.studyId !== undefined, [router.query.studyId]);

  const setBetaUIEnabled = useCallback(() => {
    _setBetaUIEnabled((prev) => {
      if (!isSavedStudy) {
        if (prev !== true) {
          dispatch(resetProjectSettings());
        } else {
          dispatch(setProjectSettings(ProjectSettings.ProblemExplorationMode));
        }
      }
      toggleBetaUI(!prev);
      return !prev;
    });
  }, [toggleBetaUI, dispatch, isSavedStudy]);

  const setBetaUIDismissed = useCallback(() => {
    _setBetaUIDismissed(true);
    markBetaUIAsDismissed();
  }, [markBetaUIAsDismissed]);

  const setSidebarMenuCollapsed = useCallback(() => {
    _setSidebarMenuCollapsed((prev) => {
      toggleSidebarMenuCollapsed(!prev);
      return !prev;
    });
  }, [toggleSidebarMenuCollapsed]);

  // If user has old UI enabled, toggle beta UI
  useEffect(() => {
    if (!getBetaUIEnabled()) {
      toggleBetaUI(true);
    }
  }, [getBetaUIEnabled, toggleBetaUI]);

  return (
    <SidebarMenuContext.Provider
      value={{
        betaUIEnabled,
        setBetaUIEnabled,
        betaUIDismissed,
        setBetaUIDismissed,
        betaUIFeatureFlagEnabled: initialBetaUIFeatureFlagEnabled,
        ragEnabled,
        surveysEnabled,
        isImpersonating,
        sidebarMenuCollapsed,
        setSidebarMenuCollapsed,
      }}
    >
      {children}
    </SidebarMenuContext.Provider>
  );
};
